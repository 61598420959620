
<ng-container *ngIf="(sidebarState$ | async) as state">
    <div [ngClass]="{ collapsed: state === 'collapsed' }" class="sidenav">
    <div class="logo">
        <img *ngIf="state === 'collapsed'" [@delayedFadeIn] src="../../../assets/img/aoc-favicon.png" />
        <img *ngIf="state === 'expanded'" [@delayedFadeIn] src="../../../assets/img/aoc-logo-text.png" />
    </div>
    <div class="sidenav--links">
        <a *ngIf="hasPermission('dashboard')" routerLink="/dashboard" routerLinkActive="active" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Dashboard' : ''" matTooltipPosition="after">grid_view</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Dashboard</div>
        </a>
        <a *ngIf="hasPermission('events')" routerLink="/events" routerLinkActive="active" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Events' : ''" matTooltipPosition="after">event</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Events</div>
        </a>
        <a *ngIf="hasPermission('reports')" routerLink="/reports" routerLinkActive="active" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Reports' : ''" matTooltipPosition="after">description</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Reports</div>
        </a>
        <a *ngIf="hasPermission('clientList')" routerLink="/client/list" routerLinkActive="active" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Client List' : ''" matTooltipPosition="after">list_alt</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Client List</div>
        </a>
        <a *ngIf="providerListFF && hasPermission('providerList')" routerLink="/provider/list" routerLinkActive="active" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Provider List' : ''" matTooltipPosition="after">list_alt</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Provider List</div>
        </a>
        <a *ngIf="resourcesListFF && hasPermission('resourcesList')" routerLink="/resources" routerLinkActive="active" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Resources List' : ''" matTooltipPosition="after">list_alt</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Resources List</div>
        </a>
        <a *ngIf="surveyMonkeyFF && hasPermission('surveyMonkey')" (click)="surveyIframeModalState$.next(true)" class="sidenav--link">
            <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Survey' : ''" matTooltipPosition="after">add_reaction</span>
            <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Survey</div>
        </a>
        <div class="nested_links--wrapper">
            <div
                *ngIf="isAdmin()"
                (click)="toggleHealthHomeAdminLinks()"
                class="sidenav--link"
                [ngClass]="{ expanded: (healthHomeAdminLinksState$ | async), active: state === 'collapsed' && (healthHomeAdminActive$ | async) }"
            >
                <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'HH Admin' : ''" matTooltipPosition="after">admin_panel_settings</span>
                <div *ngIf="state === 'expanded'" [@delayedFadeIn]>
                HH Admin
                <span [ngClass]="{ expanded: (healthHomeAdminLinksState$ | async) }" class="material-icons-round expand">expand_more</span>
                </div>
            </div>
            <!-- <div *ngIf="state === 'expanded' && (healthHomeAdminLinksState$ | async)" [@growDownOnEnter] class="nested_links">
                <a routerLink="hh-admin/client/list" routerLinkActive="active" class="nested_link">Client List</a>
                
            </div>
            <div *ngIf="state === 'collapsed' && (healthHomeAdminLinksState$ | async)" class="nested_links--collapsed" [@fadeIn]>
                <a routerLink="hh-admin/client/list" routerLinkActive="active" class="nested_link">Client List</a>
            </div> -->
            <div *ngIf="state === 'expanded' && (healthHomeAdminLinksState$ | async) && userListFF && hasPermission('userList')" [@growDownOnEnter] class="nested_links">
                <a routerLink="hh-admin/user/list" routerLinkActive="active" class="nested_link">User List</a>
            </div>
            <div *ngIf="state === 'collapsed' && (healthHomeAdminLinksState$ | async) && userListFF && hasPermission('userList')" class="nested_links--collapsed" [@fadeIn]>
                <a routerLink="hh-admin/user/list" routerLinkActive="active" class="nested_link">User List</a>
            </div>

            <div *ngIf="state === 'expanded' && (healthHomeAdminLinksState$ | async) && rolesListFF && hasPermission('rolesList')" [@growDownOnEnter] class="nested_links">
                <a routerLink="hh-admin/roles/list" routerLinkActive="active" class="nested_link">Role List</a>
            </div>
            <div *ngIf="state === 'collapsed' && (healthHomeAdminLinksState$ | async) && rolesListFF && hasPermission('rolesList')" class="nested_links--collapsed" [@fadeIn]>
                <a routerLink="hh-admin/roles/list" routerLinkActive="active" class="nested_link">Role List</a>
            </div>

            <div *ngIf="state === 'expanded' && (healthHomeAdminLinksState$ | async) && exportGoalEventsFF && hasPermission('export')" [@growDownOnEnter] class="nested_links">
                <a routerLink="hh-admin/export/all" routerLinkActive="active" class="nested_link">Exports</a>
            </div>
            <div *ngIf="state === 'collapsed' && (healthHomeAdminLinksState$ | async) && exportGoalEventsFF && hasPermission('export')" class="nested_links--collapsed" [@fadeIn]>
                <a routerLink="hh-admin/export/all" routerLinkActive="active" class="nested_link">Exports</a>
            </div>

            <div *ngIf="state === 'expanded' && (healthHomeAdminLinksState$ | async) && goalPayerSettingFF && hasPermission('goalPayerSetting')" [@growDownOnEnter] class="nested_links">
                <a routerLink="hh-admin/goal-payer-setting" routerLinkActive="active" class="nested_link">Goal Payer Setting</a>
            </div>
            <div *ngIf="state === 'collapsed' && (healthHomeAdminLinksState$ | async) && goalPayerSettingFF && hasPermission('goalPayerSetting')" class="nested_links--collapsed" [@fadeIn]>
                <a routerLink="hh-admin/goal-payer-setting" routerLinkActive="active" class="nested_link">Goal Payer Setting</a>
            </div>
        </div>
    </div>
    <div class="sidenav--bottom_links">
        <div class="links">
            <a *ngIf="hasPermission('settings')" routerLink="/settings" routerLinkActive="active" class="sidenav--link">
                <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Settings' : ''" matTooltipPosition="after">settings</span>
                <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Settings</div>
            </a>
            <a *ngIf="hasPermission('featureFlag')" routerLink="/feature-flag" class="sidenav--link">
                <span class="material-icons-round" [matTooltip]="state === 'collapsed' ? 'Feature Flag' : ''" matTooltipPosition="after">flag</span>
                <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Feature Flag</div>
            </a>
            <div class="logout sidenav--link" (click)="logout()">
                <span class="material-icons-round logout_icon" [matTooltip]="state === 'collapsed' ? 'Logout' : ''" matTooltipPosition="after">logout</span>
                <div *ngIf="state === 'expanded'" [@delayedFadeIn]>Logout</div>
            </div>
        </div>
        <div class="sidenav--user">
        <ng-container *ngIf="currentUser$ | async as user">
            <img *ngIf="user.picture; else defaultPicture" class="profile_picture" [src]="user.picture" />
            <ng-template #defaultPicture>
                <img class="profile_picture" [src]="'../../../assets/img/user.png'" />
            </ng-template>
            <a routerLink="user/account">
                <div *ngIf="state === 'expanded'" [@delayedFadeIn] class="user_details">
                    <p class="name">{{ user.firstName }} {{ user.lastName }}</p>
                    <p class="tier" [title]="getUserRole(user.role)">{{ getUserRole(user.role) }}</p>
                </div>
            </a>
        </ng-container>
        </div>
    </div>
    </div>
</ng-container>
<hh-modal *ngIf="surveyMonkeyFF && hasPermission('surveyMonkey')"
    (modalClosed)="surveyIframeModalState$.next(false)"
    [modalOpen]="surveyIframeModalState$ | async"
    [showActionButton]="!1"
    title="Survey"
    modalSize="90%"
>
    <hh-survey-iframe [url]="survey.loginUrl"></hh-survey-iframe>
</hh-modal>