import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyIFrameComponent } from './survey-iframe.component';
import { HhPipesModule } from 'src/app/pipes/hh-pipes.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SurveyIFrameComponent],
  imports: [CommonModule,HhPipesModule,ReactiveFormsModule],
  exports: [SurveyIFrameComponent]
})
export class SurveyIFrameModule {}