import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ModalModule } from '../modal/modal.module';
import { BuzzIFrameModule } from '../buzz-iframe/buzz-iframe.module';


@NgModule({
  declarations: [
    TopNavComponent
  ],
  imports: [
    CommonModule,
    MatBadgeModule,
    ModalModule,
    BuzzIFrameModule
  ],
  exports: [
    TopNavComponent
  ]
})
export class TopNavModule { }
