<div class="iframe-container">
    <!-- Iframe -->
    <ng-container>
        <div *ngIf="isLoading" class="loader"></div>
        <iframe
            [src]="url | safeUrl"
            frameborder="0"
            (load)="onIframeLoad()">
        </iframe>
    </ng-container> 
    
</div>