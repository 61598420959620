import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ModalModule } from '../modal/modal.module';
import { SurveyIFrameModule } from '../survey-iframe/survey-iframe.module';

@NgModule({
  declarations: [
    SideNavComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    RouterModule,
    ModalModule,
    SurveyIFrameModule
  ],
  exports: [
    SideNavComponent
  ]
})
export class SideNavModule { }
