import { Component, Input } from '@angular/core';

@Component({
  selector: 'hh-survey-iframe',
  templateUrl: './survey-iframe.component.html',
  styleUrls: ['./survey-iframe.component.scss']
})
export class SurveyIFrameComponent {
  @Input() url!: string;
  isLoading = true;

  onIframeLoad(): void {
    this.isLoading = false;
  }
}