import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { RootStore } from '../store/root.store';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  constructor(
    private userService: UserService,
    private store: RootStore,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean {
    console.log(route,'route')
    const routeName = route.data['name'] as string;
    console.log(routeName,'route is activated');
    if (this.userService.hasPermissions(routeName)) {
      return true;
    } else {
      //if( this.router.url === '/dashboard'){
        this.store.pageLoaderComplete$.next(true);
      //}else{
        this.router.navigateByUrl('/login');
      //}
      this.store.bannerMessage$.next('You do not have access to view this page.');
      this.store.bannerState$.next('error');
      this.store.showBanner$.next(true);
      return false;
    }
  }
}
